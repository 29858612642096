interface Window {
  location: Location;
}

interface Location {
  replace(value: string): void;
}

declare var window: Window;

const orderHistoryQuery = `query {
  purchaseHistory  {
      cancelled {
        ...itemSummaryGroups
      }
      delivered {
        ...itemSummaryGroups
      }  
      upcoming {
        ...itemSummaryGroups
      }
  }
}

fragment itemSummaryGroups on ItemGroupSummary {
  headline
  subline
  note
  id
  address {
    lines
  }
  itemGroups {
    id
    headline
    annotations {
      label
      annotationType
    }
    itemSummaries {
      id
      orderId
      purchaseId
      rxInfo {
        imageUrl
      }
      annotations {
        label
        annotationType
      }
      heading {
        headline
        subline
      }
    }
  }
}
`

const orderDetailsQuery = `query ($orderId: ID!) {
  order(id: $orderId) {
    id
    orderId
    purchaseId
    transactions {
      id
      tail
      brand
      charge
      label
      annotations {
        annotationType
        label
      }
      when {
        value
        day
        dayName
        month
        year
      }
    }
    headline
    subline
    charges {
      item
      itemLabel
      shipping
      shippingLabel
      totalBeforeTax
      totalBeforeTaxLabel
      grandTotal
      grandTotalLabel
      salesTax
      salesTaxLabel       
    }
    items {
      id
      itemId
      insurances {
        tail
        tailLabel
      }
      charges {
        copay
        copayLabel
        unitPrice
        unitPriceLabel
        item
        itemLabel
        shipping
        shippingLabel
        totalBeforeTax
        totalBeforeTaxLabel
        grandTotal
        grandTotalLabel
      }
      status {
        status
        label
      }
      rx {
        asin
        imageUrl
        prescriber
        prescriptionNumber
        ndc11
        id
        filledOn {
          month
          day
          dayName
          year
          value
                }
          headline
          subline
      }
      annotations {
        annotationType
        label
      }
    }
  }
}`


const deliveryDetailsQuery = `query ($id: ID!) {
  delivery(id: $id) {
    subline
    note
    eta
    label
    lastStepProgress
    status {
      status
    }
    address {
      lines
    }
    events {
      headline
      subline
      note
    }
    lastStepProgress
  }
}`

const invoiceDetailsQuery = `
query ($invoiceId: ID!) {
    order(id: $invoiceId) {
      id
    orderId
    purchaseId
    headline
    subline
    status {
      label
      status
    }
    orderDate
    patient
    hbaEligibleAmount
    annotations {
      annotationType
      label
    }
    orderedOn {
      value
    }
    transactions {
      id
      tail
      brand
      charge
      when {
        ...date
      }
      address {
        lines
      }
      annotations {
        annotationType
        label
      }
    }
    charges {
      ...charges
    }
    items {
      hbaEligibility {
        hbaEligible
        label
      }
      patient {
        name
      }
      delivery {
        address {
          lines
        }
      }
      id
      headline
      subline
      soldBy {
        sellerName
        address {
          lines
        }
      }
      shippingDate {
        ...date
      }
      annotations {
        annotationType
        label
      }
     	patient {
        name
      }
      charges {
        unitPrice
        unitPriceLabel
        ...charges
      }
      status {
        status
        label
      }
      rx {
        id
        headline
        subline
        imageUrl
        prescriber
        prescriptionNumber
        ndc11
        filledOn {
          ...date
        }
        asin
        nabp
        quantity {
          unit
          number
        }
      }
      shipSpeed {
        description
        duration
      }
      
    }
  }
}

fragment date on HumanizedDate {
  value
  day
  dayName
  month
  year
}
fragment charges on Charges {
  item
  itemLabel
  salesTax
  salesTaxLabel
  shipping
  shippingLabel
  totalBeforeTax
  totalBeforeTaxLabel
  grandTotal
  grandTotalLabel
  hbaEligibleTotal
  hbaEligibleTotalLabel
}`;



const gqlFetch = (query: string, variables = {}) => fetch('/your-orders-api/graphql', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  credentials: 'include',
  body: JSON.stringify({ query, variables })
})
  .then((response: any) => {
    const {status, headers} = response;
    
    switch (status) {
      case 401: {
        if (headers) {
          const location = headers.get("location");
          if (location) {
            window.location.replace(location);
          } 
        }
        throw new Error();

        // TODO: Add post to public APIGW for collecting client-side errors
        // Either GQL returned errors, or network errors

      }
      default: {
        return response.json();
      }
    }
  })
  

export const getOrderHistory = () => gqlFetch(orderHistoryQuery)

export const getDeliveryDetails = (id: string) => gqlFetch(deliveryDetailsQuery, { id })

export const getOrderDetails = (orderId: string) => gqlFetch(orderDetailsQuery, { orderId })

export const getInvoiceDetails = (invoiceId: string) => gqlFetch(invoiceDetailsQuery, { invoiceId })


