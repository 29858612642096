import React, { FunctionComponent, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

interface ForwardLinkProps {
    url: string;
    onClick?: () => void;
}

const handleClickEvent = (event: SyntheticEvent, props: ForwardLinkProps) => {
    if (props.onClick) {
        event.preventDefault();
        props.onClick()
    }
}

export const ForwardLink: FunctionComponent<ForwardLinkProps> = (props) =>
    <Link to={props.url} style={{ textDecoration: 'none' }} onClick={(e) => handleClickEvent(e, props)}>
        <pui-section flowDirection='horizontal' mainAxisArrangement='space-between'
            flex="true"
            width="100%"
            secondaryAxisArrangement="center">
            <pui-section-column>
                {props.children}
            </pui-section-column>
            <pui-section-column>
                <pui-icon imgClass='chevron-right' style={{ marginLeft: 'auto', right: '0', height: '6px', width: '6px' }} />
            </pui-section-column>
        </pui-section>
    </Link>

