import React from 'react';
import { Transaction } from '../component';
import { I18NContext } from '../../I18NContext';
import { ListDelimiter } from './ListDelimiter';
import ReactHtmlParser from 'react-html-parser';
import getAPXWidget from '../../accessors/APXWidgetAccessor';

interface PaymentProps {
    transactions: Transaction[];
    id: string;
}

interface PaymentState {
    button: React.RefObject<any>;
    bottomSheet: React.RefObject<any>;
    apxView?: string;
}

class Payment extends React.Component<PaymentProps, PaymentState> {

    private button: any;
    private bottomSheet: any;

    constructor(props: any) {
        super(props);
        this.state = {
            button: React.createRef(),
            bottomSheet: React.createRef(),
        }
    }

    _openBottomSheet(e: any) {
        e.preventDefault();
        this.state.bottomSheet.current.show();

        this.setState({
            apxView: undefined,
        })

        getAPXWidget(this.props.id)
            .then(view => this.setState({ apxView: view.htmlContent }))
            .catch(() => this.setState({ apxView: 'Error loading Payments.  Please try again later.' }));
    }

    _generatePaymentRow(transaction: Transaction) {
        const header = transaction.scenario && transaction.scenario.label === "CoPay" ? "HSA/FSA CARD" : "PAYMENT METHOD";
        const body = transaction.label;

        let needFixup = false;
        let canUpdate = false;

        transaction?.annotations.forEach(anotation => {
            if (anotation.annotationType === "UpdatePaymentMethod") {
                canUpdate = true;
            } else if (anotation.annotationType === "PayFixup") {
                canUpdate = needFixup = true;
            }
        })

        if (canUpdate) {
            return (<>
                <pui-section
                    ref={this.state.button} onClick={(e: any) => this._openBottomSheet(e)}
                    flowDirection='vertical' mainAxisArrangement='space-between'
                    flex="true" width="100%">
                    <pui-section-column spacingTop='' spacingBottom='medium'>
                        <pui-card >
                            <pui-text input={header} spacingBottom="mini" textSize="small" />
                            <pui-text input={body} />
                        </pui-card>
                    </pui-section-column>
                    {needFixup && <pui-section-column>
                        <pui-section
                            ref={this.state.button}
                            flowDirection='horizontal'>
                            <pui-icon imgClass="status-error-icon" spacingRight="small" />
                            <pui-text textSize="small" input={"Please update your payment method"} />
                        </pui-section>
                    </pui-section-column>}
                </pui-section>
            </>
            )
        } else {
            return (
                <pui-section>
                    <pui-text input={header} spacingBottom="mini" textSize="small" />
                    <pui-text input={body} />
                </pui-section>
            )
        }
    }

    render() {
        return (
            <>
                <pui-section spacingRight="small" >
                    <pui-heading spacingBottom="medium" textSize="medium" input={this.context.get('PAYMENT_INFO_HEADER')} />
                    <ListDelimiter delimiter={<pui-divider spacingTop="medium" spacingBottom="medium" ></pui-divider>} >
                        {this.props.transactions.map(transaction => this._generatePaymentRow(transaction))}
                    </ListDelimiter>
                </pui-section>
                <pui-bottom-sheet hideLink ref={this.state.bottomSheet}>
                    {!this.state.apxView ? "Loading" : ReactHtmlParser(this.state.apxView || '')}
                </pui-bottom-sheet>
            </>
        );
    }
}

Payment.contextType = I18NContext;

export default Payment;
