import Cookies from 'js-cookie';

export default async function cancelOrder(purchaseId: string, orderId: string) {  
  const csrf_token = Cookies.get('XSRF-TOKEN') || '';
  
  const response = await fetch('/your-orders-api/command/cancel', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'X-XSRF-TOKEN': csrf_token,
    },
    body: JSON.stringify({ purchaseId, orderId })
  })
  return response.json();
}
