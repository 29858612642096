import React from 'react';
import { I18NContext } from '../../I18NContext';
import CancelForm from './CancelForm';
import { Order } from '../component';
import "./Cancel.css";

interface CancelProps {
    order: Order;
}

interface CancelState {
    button: React.RefObject<any>;
    bottomSheet: React.RefObject<any>;
}

class Cancel extends React.Component<CancelProps, CancelState> {

    private button: any;
    private bottomSheet: any;

    constructor(props: any) {
        super(props);
        this.state = {
            button: React.createRef(),
            bottomSheet: React.createRef(),
        }
    }

    _openBottomSheet(e: any) {
        e.preventDefault();
        this.state.bottomSheet.current.show();
    }

    render() {
        return (<>
            <pui-section
                ref={this.state.button} onClick={(e: any) => this._openBottomSheet(e)}
                flowDirection='horizontal' mainAxisArrangement='space-between'
                flex="true" width="100%"
                secondaryAxisArrangement="center" class="clickable">
                <pui-section-column spacingTop='medium' spacingBottom='medium' >
                    <pui-text input={this.context.get('CANCEL_LABEL')} />
                </pui-section-column>
                <pui-section-column>
                    <pui-icon imgClass='chevron-right' style={{ marginLeft: 'auto', right: '0' }} />
                </pui-section-column>
            </pui-section>
            <pui-bottom-sheet hideLink ref={this.state.bottomSheet}>
                <CancelForm order={this.props.order} />
            </pui-bottom-sheet>
        </>)
    }
}

Cancel.contextType = I18NContext;

export default Cancel;