import React from 'react';
import './ExternalLink.css';

interface Window {
    location: Location;
}

interface Location {
    replace(value: string): void;
}

declare var window: Window;

interface ExternalLinkProps {
    href: string;
    children: any;
}

export default (props: ExternalLinkProps) =>
    <div className="external-link" onClick={() => window.location.replace(props.href)} >
        {props.children}
    </div>
