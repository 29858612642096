import React from 'react';
import './InvoiceAttributeRow.scss'
import './Invoice.scss'

interface InvoiceAttributeRowProps {
    label: string;
    value?: string;
    values?: string[]
}

export default (props: InvoiceAttributeRowProps) => {
    if (!props.value && (!props.values || !props.values.length)) {
        return <></>;
    }
    return <tr className={'table-row-divider'}>
        <td>
            <pui-text spacingTop={'small'} spacingBottom={'small'} input={props.label} />
        </td>
        <td>
            {
                props.values ?
                    <pui-section spacingTop="small" spacingBottom="small">
                        {props.values.map(value => <pui-text input={value} />)}
                    </pui-section> :
                    <pui-text spacingTop={'small'} spacingBottom={'small'} input={props.value} />
            }
        </td>
    </tr>
}