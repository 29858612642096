import React, { Component } from 'react';
import { I18NContext } from '../../I18NContext';

interface ChargeLineProps {
    chargeAmount: string;
    insuranceLine?: string;
}

class MedicationCardChargeLine extends Component<ChargeLineProps, {}> {
    render() {

        const { insuranceLine, chargeAmount } = this.props;

        return (
            <pui-section-column>
                <pui-section
                    flowDirection="horizontal"
                    spacingBottom="small"
                    style={{ alignItems: "center" }}
                >
                    <pui-section-column>
                        <pui-text
                            input={chargeAmount}
                            textSize="extra-large"
                            spacingRight="mini"
                        ></pui-text>
                    </pui-section-column>
                    {insuranceLine &&
                        <pui-section-column>
                            <pui-text
                                input={insuranceLine}
                                textSize="small"
                            ></pui-text>
                        </pui-section-column>}
                </pui-section>
            </pui-section-column>
        );
    }
}

MedicationCardChargeLine.contextType = I18NContext;

export default MedicationCardChargeLine;