import React from 'react';
import { I18NContext } from '../../I18NContext';
import { Order } from '../component';
import CancelFormSelect from './CancelFormSelect';
import cancelOrder from '../../accessors/CancelOrderAccessor';


interface CancelFormProps {
    order: Order;
}

interface CancelFormState {
    selectorRef: React.RefObject<any>;
    disableButton: boolean;
    alert: any,
}

class CancelForm extends React.Component<CancelFormProps, CancelFormState> {

    constructor(props: any) {
        super(props);

        this.state = {
            selectorRef: React.createRef(),
            alert: false,
            disableButton: false,
        }
    }

    render() {
        const button = this.state.disableButton ?
            <pui-button spacingTop='small' disabled onClick={() => this._onSubmit()} label={this.context.get('CANCEL_CONTINUE_BUTTON')} /> :
            <pui-button spacingTop='small' onClick={() => this._onSubmit()} label={this.context.get('CANCEL_CONTINUE_BUTTON')} />

        return (<pui-section flowDirection='vertical' mainAxisArrangement='start' secondaryAxisArrangement='start'
            flex="true">
            {this.state.alert}
            <pui-heading input={this.context.get('CANCEL_FORM_LABEL')} textSize='large' textAlign='left' />
            <pui-selector multiselect ref={this.state.selectorRef} style={{ width: '100%' }}>
                {this.props.order.items.map((item, i) => <CancelFormSelect selectButton={(disable: boolean) => this._selectItem(disable)} key={`cancel-list-item-${i}`} item={item} />)}
            </pui-selector>
            {button}
        </pui-section>
        )
    }

    _selectItem(disableButton: boolean) {
        this.setState({ disableButton });
    }

    _onSubmit() {
        this.setState({
            alert: false,
            disableButton: true,
        });

        const { orderId, purchaseId } = this.props.order;

        cancelOrder(purchaseId, orderId)
            .then((response: any) => {
                if ("CANCEL_PENDING" === response.cancellationStatus) {
                    this.setState({
                        alert: <pui-box spacingBottom="small" style={{ width: '85%' }} theme="solid">
                            <pui-text input="We are trying to cancel your order.  Please check back later." />
                        </pui-box>
                    })
                } else {
                    this.setState({
                        alert: <pui-box spacingBottom="small" style={{ width: '85%' }} theme="success">
                            <pui-text input="Successfully cancelled your order." />
                        </pui-box>
                    })
                }
            })
            .catch(() => this.setState({
                alert: <pui-box style={{ width: '85%' }} theme="alert">
                    <pui-text input="Failed to update your order.  Please contact customer service." />
                </pui-box>
            }))
    }
}


CancelForm.contextType = I18NContext;

export default CancelForm;