import React from 'react';
import { I18NContext } from './I18NContext';
import I18N from './Strings';
import PuiContainer from "./components/Container";

import { Switch, Route, HashRouter } from "react-router-dom";
import OrderHistoryHOC from './components/order/OrderHistoryHOC';
import DeliveryDetailsHOC from './components/delivery/DeliveryDetailsHOC';
import OrderDetailHOC from './components/order/OrderDetailHOC';
import InvoiceComponentHOC from './components/invoice/InvoiceComponentHOC';

export default () => {

  return (
    <div className="App">
                  <pui-sub-nav />
        <HashRouter >
          <I18NContext.Provider value={new I18N()}>
            <PuiContainer>
              <Switch>
                <Route exact path="/order/:orderId" render={(props) => <OrderDetailHOC {...props} /> } />
                <Route exact path="/invoice/:invoiceId" render={(props) => <InvoiceComponentHOC {...props} /> } />
                <Route path="/delivery/:deliveryId?" render={(props) => <DeliveryDetailsHOC checkDelivery={true} {...props} /> } />
                <Route path="/" render={(props) => <OrderHistoryHOC {...props} /> } />
              </Switch>
            </PuiContainer>
          </I18NContext.Provider>
        </HashRouter>
    </div>
  );
}