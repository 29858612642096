import Cookies from 'js-cookie';

export default async function getAPXWidget(id: string) {
  const csrf_token = Cookies.get('XSRF-TOKEN') || '';
  
  const response = await fetch(`/your-orders-api/command/get-payment-fixup-widget?gqlOrderId=${id}`, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'X-XSRF-TOKEN': csrf_token,
    },
  });
  return response.json();
}
