import React, {Component} from 'react';

/**
 * This is something the BasePage is adding around our content.
 * It is how we get
 * | gutter | content | gutter |
 * Such that our cards are centered nice and pretty!
 */
class PuiContainer extends Component {
    render() {
        return (
            <pui-tab-container class="apex-container" spacingRight="small">
                {this.props.children}
            </pui-tab-container>
        );
    }
}

export default PuiContainer