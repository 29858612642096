import React from 'react';
import { ItemGroupSummary } from '../component'
import DeliveryDetails from '../delivery/DeliveryDetails';
import { Cache } from 'aws-amplify';
import { Redirect } from 'react-router-dom';
import { getDeliveryDetails, getOrderHistory } from '../../accessors/GraphQLAccessor';


export default class OrderHistoryHOC extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            deliveryID: null,
            view: <>Loading</>,
        }
    }

    selectItemGroupSummary(itemGroupSummary: ItemGroupSummary) {
        this.setState({ view: <>Loading</> });

        const deliveryID = itemGroupSummary.id;
        Cache.setItem("deliveryID", deliveryID);
        getDeliveryDetails(deliveryID)
            .then((response: any) => {
                this.setState({
                    view: (<DeliveryDetails delivery={response.data.delivery} itemGroupSummary={itemGroupSummary} />)
                })
            })
            .catch(() => this.setState({
                view: <DeliveryDetails itemGroupSummary={itemGroupSummary} />
            }))
        this.setState({ deliveryID, itemGroupSummary });
    }

    componentDidMount() {
        getOrderHistory()
            .then((response: any) => {
                let deliveryId = this.props.match.params.deliveryId || Cache.getItem("deliveryID");
                console.log(deliveryId);
                if (deliveryId) {
                    const [itemGroupSummary] = [].concat(response.data.purchaseHistory.upcoming,
                        response.data.purchaseHistory.delivered, response.data.purchaseHistory.cancelled)
                        .filter((itemGroup: ItemGroupSummary) => itemGroup.id === deliveryId);
                        if (itemGroupSummary) {
                            //@ts-ignore
                            console.log(itemGroupSummary.id);
                            this.selectItemGroupSummary(itemGroupSummary);
                        return;
                    }
                }
                this.setState({
                    view: <Redirect to="/your-orders" />,
                })
            })
            .catch(() =>
                this.setState({
                    view: <>Error on order history :( </>,
                }));
    }

    render() {
        return (this.state.view);
    }
}
