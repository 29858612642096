import React from 'react';
import { ListDelimiter } from './ListDelimiter';
import PUILink from './PUILink';

interface BreadCrumbProps {
    history: { name: string, url: string }[];
    currentPage: string;
}

export default class BreadCrumbs extends React.Component<BreadCrumbProps, {}> {
    render() {
        return (<pui-section flowDirection='horizontal' secondaryAxisArrangement="center">

            <ListDelimiter delimiter={<pui-text spacingRight='mini' textSize='small' input='›' spacingLeft='mini' />} >
                {this.props.history.map((page, i) =>
                    <PUILink key={i + '-breadcrumb'} text={page.name} textSize='small' href={`${page.url}`} replace={true} />
                )}
                <pui-text input={this.props.currentPage} textSize='small' />
            </ListDelimiter>
        </pui-section>)
    }
}
