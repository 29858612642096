import React from 'react';
// import { Delivery, ItemGroupSummary, DeliveryStatusType } from '../component';
import HeavyDivider from '../common/HeavyDivider';
import { I18NContext } from '../../I18NContext';
import BreadCrumb from '../common/BreadCrumb';
import DeliveryTracker from './DeliveryTracker';
import { ForwardLink } from '../common/ForwardLink';
import { ListDelimiter } from '../common/ListDelimiter';
import { ItemGroupSummary, Delivery } from '../component';
import ItemMedicationSummaryCard from '../common/ItemMedicationSummaryCard';
import PUILink from '../common/PUILink';
import ExternalLink from '../common/ExternalLink';

interface DeliveryDetailsProps {
    itemGroupSummary: ItemGroupSummary;
    delivery?: Delivery;
}

class DeliveryDetails extends React.Component<DeliveryDetailsProps, {}> {

    render() {
        const { delivery, itemGroupSummary } = this.props;
        
        const button = delivery && "NotShipped" !== delivery.status.status && "None" !== delivery.status.status
            ? <DeliveryTracker headline={itemGroupSummary.headline} label={delivery?.label || itemGroupSummary.note} delivery={delivery} />
            : <></>;

        return (
            <pui-section>
                {this._generateBreadcrumbs()}
                <pui-heading input={itemGroupSummary.headline} textSize="extra-large" spacingTop='small' key={"heading-order-detail"} />
                <pui-text input={delivery?.label || itemGroupSummary.note} spacingBottom='small' />
                {button}
                <HeavyDivider />
                <pui-heading input={this.context.get('ITEMS_IN_DELIVERY')} textSize='medium' spacingBottom='small' />
                {this._generateItemDetailList()}
                {itemGroupSummary?.address &&
                    <pui-section>
                        <HeavyDivider />
                        <pui-heading input={this.context.get('DELIVERY_ADDRESS')} text='large' spacingBottom='medium' />
                        <pui-section flowDirection='horizontal' width="100%"
                            secondaryAxisArrangement="center">
                            <pui-icon imgClass="test-icon" id="pharmacy-icon" spacingRight='small' />
                            <pui-section flowDirection='vertical' width="100%">
                            {itemGroupSummary.address?.lines.map(value => <pui-text input={value} spacingRight='small' spacingLeft='medium' />)} 
                            </pui-section>
                        </pui-section></pui-section>}
                <HeavyDivider />
                <pui-heading input={this.context.get('QUESTIONS')} text='large' spacingBottom='medium' />
                <ExternalLink href='/dashboard/help/'>
                    <pui-section flowDirection='horizontal' mainAxisArrangement='space-between'>
                        <pui-section-column>
                            <pui-text input={this.context.get('CONTACT_CUSTOMER_CARE')} spacingBottom='medium' textSize='medium' />
                        </pui-section-column>
                        <pui-section-column>
                            <pui-icon imgClass='chevron-right' style={{ marginLeft: 'auto', right: '0' }} />
                        </pui-section-column>
                    </pui-section>
                </ExternalLink>
            </pui-section>
        )
    }

    _generateItemDetailList() {
        return <ListDelimiter delimiter={<pui-divider spacingTop='small' spacingBottom='medium' />}>
            {this.props.itemGroupSummary.itemGroups.map((group, i) =>
                <>{group.itemSummaries.map((summary, j) =>
                    (<>
                        {this._buildOrderIDHeader(summary.orderId, group.id)}
                        <ForwardLink url={`/order/${group.id}`} key={i + 'deldetailslink'}>
                            <ItemMedicationSummaryCard purchaseItemSummary={summary} key={i + 'deldetails-item' + j} />
                        </ForwardLink>
                    </>)
                )}</>
            )}
        </ListDelimiter>
    }

    _buildOrderIDHeader(orderId: string, id: string) {
        return (
            <pui-section flowDirection='horizontal' secondaryAxisArrangement="center">
                <pui-text input={'Order #:&nbsp'} />
                <PUILink text={orderId} href={`/order/${id}`} />
            </pui-section>
        )
    }

    _generateBreadcrumbs() {
        return (<BreadCrumb currentPage='Delivery'
            history={[{ name: 'Your orders', url: '/' }]} />)
    }
}

DeliveryDetails.contextType = I18NContext;

export default DeliveryDetails;
