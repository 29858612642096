import React from 'react';

import './OrderSummary.scss';
import { DeliveryEvent } from '../component';


interface OrderSummaryProps {
    events: DeliveryEvent[],
    lastStepProgress: number;
}

export default (props: OrderSummaryProps) => {
    const eventView = props.events.map((event, i, arr) => {
        let progressMarker = (<pui-text class={`order-status-icon order-status-complete`} input='&#10004;' textSize='large' textAlign='center' textColor='white' />)
        let className = 'order-progress-bar progress-100'
        if (i === arr.length - 1) {
            className = '';
            if (props.lastStepProgress !== 100) {
                progressMarker = (<pui-text class={'order-status-icon'} input='&#9679;' textSize='large' textAlign='center' textColor='grey' />);
            }
        } else if (i === arr.length - 2) {
            className = `order-progress-bar progress-${props.lastStepProgress}`
        }

        const possibleHeadling = [event.note, event.subline, event.headline]
            .filter(text => !!text);

        return (
            <pui-section flowDirection="horizontal" key={i + '-ordersummary'}>
                <pui-section-column spacingLeft="small" spacingRight="medium" class={className} >
                    {progressMarker}
                </pui-section-column>
                <pui-section-column>
                    <pui-text input={possibleHeadling.pop()} fontWeight="bold" />
                    <pui-text input={possibleHeadling.pop()} fontWeight="normal" textSize="small" />
                </pui-section-column>
            </pui-section>
        )
    });

    return (<pui-section flex={"true"} flowDirection={"vertical"}>{eventView}</pui-section>);
}