import React from 'react';
import OrderHistory from "./OrderHistory";
import { getOrderHistory } from '../../accessors/GraphQLAccessor';


export default class OrderHistoryHOC extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      deliveryID: null,
      view: <>Loading</>,
    }
  }

  componentDidMount() {
    getOrderHistory()
      .then((response: any) => {
        this.setState({
          view: (<OrderHistory purchaseHistory={response.data.purchaseHistory} />)
        })
      })
      .catch((error: any) => {
        this.setState({
          view: (<>Error on order history </>),
        })
      });

  }

  render() {
    return (this.state.view);
  }
}
