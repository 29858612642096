import React from 'react';
import OrderDetail from './OrderDetail';
import { getOrderDetails } from '../../accessors/GraphQLAccessor';

export default class OrderDetailHOC extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      deliveryID: null,
      view: <>Loading</>,
    }
  }

  componentDidMount() {
    const orderId = this.props.match.params.orderId;

    getOrderDetails(orderId)
      .then((response: any) => {
        this.setState({
          view: <OrderDetail order={response.data.order} />
        })
      })
      .catch(() => {
        this.setState({
          view: (<>Error</>),
        })
      });

  }

  render() {
    return (this.state.view);
  }
}

