import React from 'react';
import InvoiceComponent from './InvoiceComponent';
import { getInvoiceDetails } from '../../accessors/GraphQLAccessor';

export default class InvoiceDetailHOC extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      deliveryID: null,
      view: <>Loading</>,
    }
  }

  componentDidMount() {
    const orderId = this.props.match.params.invoiceId;

    getInvoiceDetails(orderId)
      .then((response: any) => {
        this.setState({
          view: <InvoiceComponent order={response.data.order} />
        })
      })
      .catch(() => {
        this.setState({
          view: (<>Error on order history </>),
        })
      });

  }

  render() {
    return (this.state.view);
  }
}

