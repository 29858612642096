import React from 'react';

import { I18NContext } from '../../I18NContext';
import Help from '../Help';
import { Order } from '../component';
import MedicationCardChargeLine from '../common/MedicationCardChargeLine';
import Charges from '../Charges';
import BreadCrumb from '../common/BreadCrumb';
import HeavyDivider from '../common/HeavyDivider';
import { ListDelimiter } from '../common/ListDelimiter';
import Cancel from '../common/Cancel';
import Payment from '../common/Payment';
import ExternalLink from '../common/ExternalLink';
import ItemMedicationSummaryCard from '../common/ItemMedicationSummaryCard';

interface OrderDetailProps {
    order: Order;
}

class OrderDetail extends React.Component<OrderDetailProps, {}>  {
    render() {
        const { order } = this.props;

        const canCancel = order.items.filter((item) => item.status.status === "None" || item.status.status === "NotShipped").length > 0

        const [firstItem] = order.items;

        return (
            <pui-section
                spacingTop="small"
                spacingBottom="small"
                style={{ width: "100%" }}>

                {this._generateBreadcrumbs()}
                <pui-heading input={order.headline} textSize='large' spacingTop="small" />
                <pui-text input={order.subline} spacingTop='small' />
                <HeavyDivider />
                <pui-heading input='Items in this order' textSize='medium' spacingBottom='small' />
                {this._generatedMedicalCards()}
                <pui-divider spacingBottom='medium' />
                <Charges {...order.charges} />
                <HeavyDivider />
                <Payment transactions={order.transactions} id={order.id} />
                {firstItem?.insurances && firstItem.insurances.length > 0 && this._generateInsuranceInformation()}
                <HeavyDivider />
                <pui-section-column spacingTop="small">
                    <pui-heading textSize="medium" input={this.context.get('HELP')} />
                    <Help orderId={order.id}>
                        {canCancel && <Cancel order={order} />}
                    </Help>
                </pui-section-column>
            </pui-section>);
    }

    _generateBreadcrumbs() {
        const history = [{ url: '/', name: "Your Orders" },
        { url: '/delivery', name: "Delivery" }];

        return (<BreadCrumb history={history} currentPage={'Order'} />)
    }

    _generatedMedicalCards() {
        return (<ListDelimiter delimiter={<pui-divider spacingTop='small' spacingBottom='small' />}>
            {this.props.order.items.map((item) =>
                <ExternalLink href={`/dp/${item.rx.asin}`}>
                    <ItemMedicationSummaryCard item={item} >
                        {item.charges && <pui-section spacingTop='small'>
                            <MedicationCardChargeLine chargeAmount={item.charges?.item} insuranceLine={item.insurances && item.insurances[0]?.tailLabel} />
                        </pui-section>}
                    </ItemMedicationSummaryCard>
                </ExternalLink>
            )}
        </ListDelimiter>);
    }

    _generateInsuranceInformation() {
        return (<pui-section spacingRight="small" >
            <HeavyDivider />
            <pui-heading spacingBottom="small" textSize="medium" input={this.context.get('INSURANCE_INFO_HEADER')} />
            <ListDelimiter delimiter={<pui-divider spacingTop="medium" spacingBottom="medium" ></pui-divider>} >
                {this.props.order.items
                    .filter(item => item.insurances)
                    .map(item =>
                        <> {item.insurances
                            .map(insurance => {
                                return (<>
                                    <pui-section flowDirection="horizontal" mainAxisArrangement="space-between">
                                        <pui-section-column spacingRight="small" >
                                            <pui-text input={"INSURANCE CARD"} spacingBottom="small" textSize="small" />
                                            <pui-text input={"• • • • • • " + insurance.tail}  spacingBottom="medium" textSize="medium"/>
                                        </pui-section-column>
                                    </pui-section>
                                </>)
                            })
                        }
                        </>)
                }
            </ListDelimiter>
        </pui-section>);
    }

}

OrderDetail.contextType = I18NContext;

export default OrderDetail;
