import React from 'react';
import { Delivery } from "../component";
import { I18NContext } from '../../I18NContext';
import OrderSummary from './OrderSummary';

interface DeliveryTrackerProps {
    delivery: Delivery;
    headline: string;
    label: string;
}

class DeliveryTracker extends React.Component<DeliveryTrackerProps, {}> {

    private bottomSheet: any;

    constructor(props: DeliveryTrackerProps) {
        super(props);
        this.bottomSheet = React.createRef();
    }

    render() {

        const { delivery, headline, label } = this.props;
        const { subline, note, status, events, lastStepProgress } = delivery;

        let theme = 'secondary';
        if (status.status !== "Delivered") {
            theme = 'primary';
            events.push({
                headline: headline,
                subline: delivery.address?.lines.join(', ') || '',
            });
        }

        return (<>
            <pui-button label={this.context.get('TRACK_THIS_DELIVERY')} onClick={() => this._onClick()} theme={theme} />
            <pui-bottom-sheet hideLink ref={this.bottomSheet}>
                <pui-heading input={`${label}`} textSize='large' class='close' />
                <pui-text input={`${subline}`} spacingTop='small' />
                <pui-text input={`${note}`} spacingTop='small' spacingBottom='medium' />
                <OrderSummary events={events}
                    lastStepProgress={lastStepProgress} />
            </pui-bottom-sheet>
        </>
        )
    }

    _onClick() {
        this.bottomSheet.current.show();
    }
}

DeliveryTracker.contextType = I18NContext;

export default DeliveryTracker;
