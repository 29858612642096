import React from 'react';
import { I18NContext } from '../../I18NContext';
import { Item, Order } from '../component';
import InvoiceCharges from './InvoiceCharges';
import InvoiceAttributeRow from './InvoiceAttributeRow';
import { formatMonDDYYY } from '../common/DateFormatter';

interface InvoiceItemSummaryTagProps {
    item: Item;
    order: Order;
}

class InvoiceItemSummaryTag extends React.Component<InvoiceItemSummaryTagProps, {}> {
    render() {
        const { item } = this.props;

        let filled;
        let insurances;
        let shippingDate;

        if (item.filledOn) {
            filled = <InvoiceAttributeRow label={this.context.get('INV_FILLED_ON')} value={formatMonDDYYY(item.filledOn?.value)} />
        }

        if (item.insurances) {
            insurances = <InvoiceAttributeRow label={this.context.get('INV_INSURANCE')} values={item.insurances?.map(ins => ins.provider)} />
        }

        if (item.shippingDate) {
            shippingDate = <InvoiceAttributeRow label={this.context.get('INV_SHIPPING_DATE')} value={formatMonDDYYY(item.shippingDate?.value)} />
        }

        return (
            <pui-section>
                <pui-heading input={item.rx.headline} />
                <pui-text input={item.rx.subline} />
                {item.hbaEligibility.hbaEligible === "Eligible" && <pui-text input={this.context.get('HBA_ELIGIBLE')} />}
                <table style={{ width: '100%' }}>
                    <tbody>
                        <InvoiceAttributeRow label={this.context.get('INV_NDC')} value={item.rx?.ndc11} />
                        <InvoiceAttributeRow label={this.context.get('INV_ITEM_QUANTITY')} value={item.rx?.quantity?.number} />
                        <InvoiceAttributeRow label={this.context.get('INV_UNIT_PRICE')} value={item.charges.unitPrice} />
                        <InvoiceAttributeRow label={this.context.get('INV_PATIENT')} value={item.patient?.name} />
                        <InvoiceAttributeRow label={this.context.get('INV_PRESCRIPTION_NUM')} value={item.rx?.prescriptionNumber} />
                        <InvoiceAttributeRow label={this.context.get('INV_PRESCRIBER')} value={item.rx?.prescriber} />
                        {filled}
                        {insurances}
                        <InvoiceAttributeRow label={this.context.get('INV_SOLD_BY')} value={item.soldBy?.sellerName} />
                        <InvoiceAttributeRow label={this.context.get('INV_NABP_NPI')} value={item.rx?.nabp} />
                        {shippingDate}
                        <InvoiceCharges {...item.charges} />
                    </tbody>
                </table>
            </pui-section>
        )
    }
}

InvoiceItemSummaryTag.contextType = I18NContext;

export default InvoiceItemSummaryTag;