import React from 'react';

interface RowInput {
    label?: string,
    value?: string,
    fontWeight?: string,
    spacingTop?: string,
    spacingBottom?: string,
}

export default (props: RowInput) => {
    const {label, value, fontWeight, spacingTop, spacingBottom} = props;
    const styling = {fontWeight, spacingTop, spacingBottom};
    if (!value || !label) {
        return (<></>);
    }

    return (<tr>
        <td>
            <pui-text {...styling} input={`${label}:`} textAlign="left" />
        </td>
        <td>
            <pui-text {...styling} input={value} textAlign="right" />
        </td>
    </tr>)
}
