import React from 'react';
import { Link } from "react-router-dom"

interface PUILinkProps {
    href: string;
    text: string;
    textSize?: string;
    textColor?: string;
    fontWeight?: string;
    replace?: boolean;
    align?: "left" | "right" | "center";
}

const defaultProps = {
    textSize: 'large',
    textColor: 'link',
    fontWeight: 'normal',
    align: 'right',
}

export default (props: PUILinkProps) =>
    <Link to={props.href} replace={props.replace} className={`pui-link text-align-${props.align || defaultProps.align}`}>
        <pui-text {...defaultProps} {...props} input={props.text} />
    </Link>