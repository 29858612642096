import React from 'react';

import { I18NContext } from '../I18NContext';
import PUILink from './common/PUILink';
import ExternalLink from './common/ExternalLink';

interface HelpProps {
    orderId: string;
}

class Help extends React.Component<HelpProps, {}> {

    render() {
        let children;
        if (this.props.children) {
            children = (<><pui-section-column spacingTop='medium'>
                {this.props.children}
            </pui-section-column>
                <pui-divider /></>);
        }

        return (<pui-section flowDirection='vertical'>
            {children}
            <pui-section-column spacingTop='medium'>
                <PUILink text={this._generateHelpLinkContent(this.context.get('VIEW_INVOICE'))} href={`/invoice/${this.props.orderId}`} replace={true} />
            </pui-section-column>
            <pui-divider></pui-divider>
            <pui-section-column spacingTop='medium'>
                <ExternalLink href='/dashboard/help/'>
                    <pui-section flowDirection='horizontal' mainAxisArrangement='space-between'>
                        <pui-section-column>
                            <pui-text input='Contact customer care' spacingBottom='medium' textSize='medium' />
                        </pui-section-column>
                        <pui-section-column>
                            <pui-icon imgClass='chevron-right' style={{ marginLeft: 'auto', right: '0' }} />
                        </pui-section-column>
                    </pui-section>
                </ExternalLink>
            </pui-section-column>
        </pui-section>
        );
    }

    _generateHelpLinkContent(linkName: string) {
        return `<pui-section flowDirection='horizontal' mainAxisArrangement='space-between'>
        <pui-section-column>
            <pui-text input='${linkName}' spacingBottom='medium' textSize='medium' />
        </pui-section-column>
        <pui-section-column>
            <pui-icon imgClass='chevron-right' style={{ marginLeft: 'auto', right: '0' }} />
        </pui-section-column>
    </pui-section>`
    }
}

Help.contextType = I18NContext;

export default Help;
