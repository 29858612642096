import 'core-js/es';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from "./App";
import I18N from './Strings'
// Server needs to drop the static strings off in a script tag so we can get that global and load it.
// This is an example temporarily...
(function() {
    /*
    const {i18n} = I18NContext._currentValue;
    i18n.addLocale("mx-US", {
        CHARGE_TAX_MESSAGE: "(Incluye impuestos y envío)"
    });
    */
   I18N.add("en-US", {
    ORDER_DATE_FORMAT: "Order Placed: $MONTH $DAY, $YEAR",
    ORDER_ID_FORMAT: "Order number: $ORDER_ID",
    BILLING_ADDRESS: 'Billing address:',
    DELIVERY_ADDRESS: 'Delivery Address',
    SHIPPING_SPEED_LABEL: 'Shipping speed:',
    PAYMENT_INFO_LABEL: 'PAYMENT METHOD',
    HBA_ELIGIBLE: 'FSA or HSA Eligible',
    ITEMS_IN_DELIVERY: 'Item(s) in this delivery',
    QUESTIONS: 'Questions?',
    ORDER_INFORMATION: 'Order information',
    HELP: 'Need help with this order?',
    TRACK_THIS_DELIVERY: 'Track this delivery',
    PAYMENT_INFO_HEADER: 'Payment information',
    INSURANCE_INFO_HEADER: 'Insurance Information',
    VIEW_INVOICE: 'View invoice',
    CONTACT_CUSTOMER_CARE: 'Contact customer care',
    CANCEL_ORDER: 'Cancel this order',
    HELP_LINK: 'Help',
    ORDER_HISTORY_HEADER: 'Your orders',

    INV_FSA_HSA_ELIGIBLE_AMOUNT: 'FSA/HSA eligible amount',
    INV_ORDER_NUMBER: 'Order&nbsp;#:',
    INV_ORDER_DATE: 'Order&nbsp;date:',
    INV_DELIVERY_ADDRES: 'Delivery&nbsp;address:',
    INV_SHIPPING_SPEED: 'Shipping&nbsp;speed:',
    INV_PAYMENTS: 'Payments:',
    INV_BILLING_ADDRESS: 'Billing&nbsp;address:',
    INV_NDC: 'NDC&nbsp;#:',
    INV_ITEM_QUANTITY: 'Item&nbsp;quantity:',
    INV_UNIT_PRICE: 'Unit&nbsp;price:',
    INV_PATIENT: 'Patient:',
    INV_PRESCRIBER: 'Prescriber:',
    INV_PRESCRIPTION_NUM: 'Prescription&nbsp;#:',
    INV_FILLED_ON: 'Filled&nbsp;on:',
    INV_INSURANCE: 'Insurance:',
    INV_SOLD_BY: 'Sold&nbsp;by',
    INV_NABP_NPI: 'NABP/NPI:',
    INV_SHIPPING_DATE: 'Shipping&nbsp;date:',

    CANCEL_LABEL: 'Cancel items in this order',
    CANCEL_FORM_LABEL: 'What would you like to cancel?',
    CANCEL_CONTINUE_BUTTON: 'Continue',
});
})();

ReactDOM.render( <App />, document.getElementById('root'));
