import React from 'react';
import { I18NContext } from '../../I18NContext';
import { Item } from '../component';

interface CancelFormSelectProps {
    item: Item;
    selectButton: (disable: boolean) => void;
}

interface CancelFormSelectState {
    buttonRef: React.RefObject<any>;
    cardRef: React.RefObject<any>;
    selected: boolean;
}

class CancelFormSelect extends React.Component<CancelFormSelectProps, CancelFormSelectState> {
    constructor(props: any) {
        super(props);

        this.state = {
            buttonRef: React.createRef(),
            cardRef: React.createRef(),
            selected: true,
        }
    }

    componentDidMount() {
        if (this.state.buttonRef.current) {
            this.state.buttonRef.current.check();
        }
    }

    render() {
        const { item } = this.props;
        return (
            <pui-selector-item value={item} ref={this.state.cardRef} onClick={() => this._onSelect()}>
                <pui-section flowDirection='horizontal' mainAxisArrangement='start' secondaryAxisArrangement='center' spacingLeft='medium' spacingTop='small' spacingBottom='small'>
                    <pui-section-column spacingLeft='small' spacingRight='small' spacingBottom='medium'>
                        <pui-checkbox ref={this.state.buttonRef} />
                    </pui-section-column>
                    <pui-section-column >
                        <pui-text input={item.rx.headline} fontWeight='bold' />
                        <pui-text input={item.rx.subline} />
                    </pui-section-column>
                </pui-section>
            </pui-selector-item>
        )
    }

    _onSelect() {
        if (this.state.selected) {
            this.state.buttonRef.current.uncheck();
        } else {
            this.state.buttonRef.current.check();
        }
        this.props.selectButton(this.state.selected);
        this.setState({ selected: !this.state.selected });
    }


}

CancelFormSelect.contextType = I18NContext;

export default CancelFormSelect;