import React from 'react';
import { PurchaseHistory, ItemGroupSummary } from "../component";
import HeavyDivider from '../common/HeavyDivider';
import { ForwardLink } from '../common/ForwardLink';
import { ListDelimiter } from '../common/ListDelimiter';
import { I18NContext } from '../../I18NContext';
import ItemMedicationSummaryCard from '../common/ItemMedicationSummaryCard';
import ExternalLink from '../common/ExternalLink';
import ItemAnnotations from '../common/ItemAnnotations';

interface OrderHistoryProps {
    purchaseHistory: PurchaseHistory;
}

class OrderHistory extends React.Component<OrderHistoryProps, {}> {

    render() {
        return (
            <pui-section>
                {this._generateHeader()}
                <HeavyDivider />
                <ListDelimiter delimiter={<HeavyDivider />}>
                    {this._generateUpcomingItemGroupSummary()}
                    <ListDelimiter delimiter={<pui-divider spacingTop="small" spacingBottom="medium" />}>
                        {this._generatePreviousItemGroupSummary()}
                    </ListDelimiter>
                </ListDelimiter>
            </pui-section>
        )
    }

    _generateUpcomingItemGroupSummary() {
        return this.props.purchaseHistory.upcoming.map(summary =>
            <ListDelimiter delimiter={<HeavyDivider />}>
                <pui-section >
                    {summary.headline && <pui-heading input={summary.headline} spacingLeft="small" textSize="large" />}
                    {summary.subline && <pui-text input={summary.subline} spacingLeft="small" />}
                    {this._processItemGroups(summary)}
                </pui-section>
            </ListDelimiter>)
    }

    _generatePreviousItemGroupSummary() {
        const previousOrders = [...this.props.purchaseHistory.delivered, ...this.props.purchaseHistory.cancelled]

        return (<pui-section >
            {previousOrders.length > 0 && <pui-heading input={"Past orders"} spacingLeft="small" textSize="large" />}
            <ListDelimiter delimiter={<pui-divider />}>
                {previousOrders.map(summary => this._processItemGroups(summary, true))}
            </ListDelimiter>
        </pui-section>)
    }


    _processItemGroups(itemGroupSummary: ItemGroupSummary, previous: boolean = false) {
        return (
            <ForwardLink url={`/delivery/${itemGroupSummary.id}`} >
                {itemGroupSummary.itemGroups.map((itemGroup) => <>
                    {itemGroup.itemSummaries.map((itemSummary, i) => <ItemMedicationSummaryCard purchaseItemSummary={itemSummary} key={i + '-orderhistorymedcard'} >
                        {previous && <pui-text input={itemGroupSummary.headline} textColor={'grey'} />}
                    </ItemMedicationSummaryCard >)}
                    {(itemGroup.annotations.length > 0) && <pui-section spacingLeft="large" >
                        <ItemAnnotations annotations={itemGroup.annotations} />
                    </pui-section>}
                </>
                )}
            </ForwardLink>)
    }

    _generateHeader() {
        const { upcoming } = this.props.purchaseHistory;

        return (
            <pui-section >
                <pui-section flowDirection="horizontal" secondaryAxisArrangement="center" mainAxisArrangement="space-between" spacingLeft="small" spacingRight="small">
                    <pui-section-column>
                        <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
                            <pui-icon imgClass="dashboard-default-shipping-icon" spacingRight="small" />
                            <pui-heading input={this.context.get('ORDER_HISTORY_HEADER')} textSize="extra-large" />
                        </pui-section>
                    </pui-section-column>
                    <pui-section-column spacingRight="small">
                        <pui-section secondaryAxisArrangement="end">
                            <ExternalLink href='/dashboard/help' >
                                <pui-link text='Help' />
                            </ExternalLink>
                        </pui-section>
                    </pui-section-column>
                </pui-section>
                {(!upcoming || upcoming.length === 0) &&
                    <pui-section secondaryAxisArrangement="center" mainAxisArrangement="space-between" spacingTop="medium" style={{ width: '100%', border: '2px dashed lightgray', borderRadius: '12px' }} >
                        <pui-text input='No upcoming orders.' spacingTop="medium" spacingBottom="medium" textColor="grey" />
                    </pui-section>}
            </pui-section>
        )
    }
}

OrderHistory.contextType = I18NContext;

export default OrderHistory;
