import React from 'react';

import { Annotation } from "../component";

interface ItemAnnotationProps {
    annotations: Annotation[];
}

function generateAnnotationLine(annotation: Annotation) {
    const icon = annotation.annotationType === "PayFixup"
        ? <pui-icon imgClass="status-error-icon" spacingRight="small" />
        : <></>;

    const color = annotation.annotationType === "ReplacedBy"
        || annotation.annotationType === "Replaces"
        ? 'grey' : 'black';

    return (<pui-section style={{ textDecoration: "none" }} flowDirection="horizontal">
        {icon} <pui-text input={annotation.label} textColor={color} />
    </pui-section >)
}

export default (props: ItemAnnotationProps) => {
    const duplicates = new Set();

    const annotationChild = props.annotations
        .filter(annotation => annotation.annotationType !== "Cancel")
        .filter(annotation => {
            const isDuplicate = duplicates.has(annotation.annotationType);
            duplicates.add(annotation.annotationType);
            return !isDuplicate;
        })
        .map(annotation => {
            duplicates.add(annotation.annotationType)
            return generateAnnotationLine(annotation);
        });

        return (<> 
            {annotationChild}
        </>)
}