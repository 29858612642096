import React from 'react';
import { I18NContext } from '../../I18NContext';
import BreadCrumb from '../common/BreadCrumb';
import { formatMonDDYYY } from '../common/DateFormatter';
import HeavyDivider from '../common/HeavyDivider';
import { ListDelimiter } from '../common/ListDelimiter';
import { Order } from '../component';
import './Invoice.scss';
import './InvoiceAttributeRow';
import InvoiceAttributeRow from './InvoiceAttributeRow';
import InvoiceCharges from './InvoiceCharges';
import InvoiceItemSummary from './InvoiceItemSummary';


interface InvoiceComponentProps {
    order: Order;
    deliveryId?: string;
}

class InvoiceComponent extends React.Component<InvoiceComponentProps, {}>  {

    render() {
        const { order } = this.props;

        return (
            <pui-section
                style={{ width: "100%" }}>
                {this._generateBreadcrumbs()}
                <pui-heading input={order.headline} spacingTop="small" />
                <pui-text input={order.subline} />
                <HeavyDivider />
                <ListDelimiter delimiter={<HeavyDivider />} >
                    {order.items.map((item, i) => <InvoiceItemSummary order={order} item={item} key={i + '-invoicecomponent'} />)}
                </ListDelimiter>
                <HeavyDivider />
                {this._generateOrderInformation()}
                {(order.charges.hbaEligibleTotal !== "$0.00" && !!order.charges.hbaEligibleTotal) &&
                    <pui-section>
                        <HeavyDivider />
                        <pui-heading input={order.charges.hbaEligibleTotalLabel} />
                        <pui-text input={order.charges.item} />
                    </pui-section>}
            </pui-section>);
    }

    _generateBreadcrumbs() {
        const history = [{ url: '/', name: 'Your orders' },
        { url: `/delivery`, name: 'Delivery' },
        { url: `/order/${this.props.order.id}`, name: 'Order' }]

        return (<BreadCrumb history={history} currentPage={'Invoice'} />);
    }

    _generateOrderInformation() {
        const { order } = this.props;
        const [firstItem] = order.items;
        const [firstTransaction] = order.transactions;

        const paymentDetails = order.transactions
            .map(transaction => `${transaction.brand} ending in ${transaction.tail}`);
        if (order.orderedOn) {
            const transactionDate = formatMonDDYYY(order.orderedOn.value);
            paymentDetails.push(`Transaction on ${transactionDate}`);
        }

        return (
            <pui-section>
                <pui-heading input={this.context.get('ORDER_INFORMATION')} />
                <table style={{ width: '100%' }}>
                    <tbody>
                        <InvoiceAttributeRow label={'Order&nbsp;#:'} value={order.orderId} />
                        {order.orderedOn && <InvoiceAttributeRow label={'Order&nbsp;date:'} value={formatMonDDYYY(order.orderedOn.value)} />}
                        {firstItem.delivery?.address?.lines?.length > 0 && <InvoiceAttributeRow label={'Delivery&nbsp;address:'} values={firstItem.delivery.address.lines} />}
                        <InvoiceAttributeRow label={'Shipping&nbsp;speed:'} value={firstItem.shipSpeed?.description} />
                        <InvoiceAttributeRow label={'Payment(s):'} values={paymentDetails} />
                        {firstTransaction?.address?.lines?.length && <InvoiceAttributeRow label={'Billing address:'} values={firstItem.delivery.address.lines} />}
                        <InvoiceCharges {...order.charges} />
                    </tbody>
                </table>
            </pui-section>
        )
    }
}

InvoiceComponent.contextType = I18NContext;

export default InvoiceComponent;