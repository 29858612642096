
export const formatMonDDYYY = (input: string): string => {
    if (!input) return '';

    const date = new Date(input);

    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' })
    const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);

    return `${month} ${day}, ${year}`;
}
