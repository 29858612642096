import React from 'react';
import { Item, PurchasedItemSummary } from '../component';
import MedicationCard from './MedicationCard';
import { Annotation } from "../component"
import ItemAnnotations from './ItemAnnotations';


interface ItemMedicationSummaryCardProps {
    item?: Item;
    purchaseItemSummary?: PurchasedItemSummary;
    children?: any;
}

export default (props: ItemMedicationSummaryCardProps) => {
    const { item, purchaseItemSummary } = props;

    let crossedOut = false;

    const ignoredAnnotations = new Set();
    ignoredAnnotations.add("UpdatePaymentMethod")
    ignoredAnnotations.add("Cancel")

    let annotations: Annotation[] = item?.annotations || purchaseItemSummary?.annotations || [];
    annotations = annotations.filter(annotation => !ignoredAnnotations.has(annotation.annotationType));
    annotations.forEach(annotation => {
        if (annotation.annotationType === 'ReplacedBy') crossedOut = true;;
    });
    
    const imageUrl = item?.rx.imageUrl || purchaseItemSummary?.rxInfo?.imageUrl;
    const headline = item?.rx.headline || purchaseItemSummary?.heading.headline || '';
    const subline = item?.rx.subline || purchaseItemSummary?.heading.subline;

    return (<MedicationCard headline={headline} subline={subline} imageURL={imageUrl} crossedOut={crossedOut}>
        <ItemAnnotations annotations={annotations} />
        {props.children}
    </ MedicationCard>)
}
