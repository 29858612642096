import React, { Component } from 'react';
import { I18NContext } from '../../I18NContext';

// Derived from https://code.amazon.com/packages/ApexDashboardHZAVingilot/blobs/mainline/--/webapp/WEB-INF/tags/pyo/rx-card.tag

interface MedicationCardProps {
    headline: string;
    subline?: string;
    imageURL?: string;
    crossedOut?: boolean;
}

class MedicationCard extends Component<MedicationCardProps, {}> {
    render() {
        const { headline, subline, imageURL, crossedOut } = this.props;
        const styling = crossedOut ? { textDecorationLine: 'line-through' } : {};

        const img = imageURL ? (<img
            src={imageURL || ''}
            width="48px"
            height="48px"
            className="aok-align-center-horizontally"
            alt=""
        />) : (<pui-icon imgClass='rx-bottle-icon' style={{height: '48px', width: '48px'}}/>)

        return (
            <pui-section
                flex="true"
                flowDirection="horizontal"
                spacingTop="small"
                spacingBottom="small"
                width="100%"
                secondaryAxisArrangement="center"
                mainAxisArrangement="start">

                <pui-section-column
                    top="true"
                    flexGrid="0 0 auto">
                    {img}

                </pui-section-column>
                <pui-section-column spacingLeft="small">
                    <pui-section
                        flowDirection="vertical">
                        <pui-section-column>
                            <pui-text
                                style={styling}
                                data-ref="headline"
                                textSize="large"
                                fontweight="bold"
                                input={headline}
                            ></pui-text>
                            <pui-text
                                style={styling}
                                data-ref="rx-supply-label"
                                input={subline}
                            ></pui-text>
                        </pui-section-column>
                        {this.props.children}
                    </pui-section>
                </pui-section-column>
            </pui-section>
        );
    }
}

MedicationCard.contextType = I18NContext;


export default MedicationCard;